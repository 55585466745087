<script setup lang="ts">
  defineProps<{
    opened: boolean
    sizeMax?: 'sm' | 'md' | 'lg' | 'xl'
    width?: string
    fullWidth?: boolean
    notDismissible?: boolean
  }>()

  const emit = defineEmits(['close'])

  const slots = useSlots()
</script>

<template>
  <ModalBase
    :opened="opened"
    :full-width="fullWidth"
    class="fixed left-0 top-0 z-50 size-full items-end justify-center sm:items-center"
    @close="emit('close')"
  >
    <div
      class="relative w-full rounded-t-2xl bg-white shadow-[0px_0px_20px_6px_hsl(0deg_0%_0%_/_10%)] dark:bg-dark-700 sm:mx-auto sm:w-auto sm:rounded-lg sm:rounded-b-2xl md:min-w-[600px]"
      :class="{
        'sm:max-w-screen-sm': !sizeMax || sizeMax === 'sm',
        'sm:max-w-screen-md': sizeMax === 'md',
        'sm:max-w-screen-lg': sizeMax === 'lg',
        'sm:max-w-screen-xl': sizeMax === 'xl',
      }"
      :style="{
        width,
      }"
    >
      <div
        ref="targetContent"
        class="flex h-full max-h-[80vh] flex-col overflow-y-auto overflow-x-hidden md:max-h-[90vh]"
      >
        <div
          v-if="slots.header || slots.title"
          class="px-6 pt-6"
        >
          <slot name="header">
            <div
              v-if="slots.title"
              class="relative flex items-center justify-between gap-3 border-b pb-6 dark:border-dark-500"
            >
              <h3 class="my-0 text-lg font-semibold dark:text-dark-100">
                <slot name="title" />
              </h3>

              <div
                v-if="!notDismissible"
                class="-mx-2 h-8 w-8 cursor-pointer p-1.5 text-dark-400 transition hover:text-dark-900 dark:hover:text-dark-200"
                @click.prevent.stop="emit('close')"
              >
                <SvgImage symbol="icon-x-mark" />
              </div>
            </div>
          </slot>
        </div>

        <div class="overflow-y-auto overflow-x-hidden px-4 py-8 sm:px-7">
          <slot />
        </div>

        <div
          v-if="slots.footer"
          class="px-7 py-6 empty:px-0 empty:py-0"
          :class="{
            'pt-6': !slots.default,
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </ModalBase>
</template>
